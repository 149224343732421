import { Col, Container, Row } from "react-bootstrap";

export default function Label(props) {
    return <div className='m-3'>
    <Container>
      <Row>
        <Col>
        <div style={{
          width: '23em',
          border: '2px solid black',
          padding: '0.5em'
        }}>
        <h1 className='m-0'><b>Supplement Facts</b></h1>
        <p className='m-0'>Serving Size: {props.servingSize}</p>
        <hr  />
        
        <Row>
          <Col><strong>Amount Per Capsule</strong></Col>
          <Col style={{textAlign: 'right'}}><strong>% Daily Value</strong></Col>
        </Row>
        <hr className='slim' />
        <Row>
          <Col>{props.supplement}</Col>
          <Col style={{textAlign: 'right'}}>{props.dailyValue}</Col>
        </Row>
        <hr />
        </div>
        </Col>
        
      </Row>
    </Container>
  </div>
}