import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Label from './Label';
import { useState } from 'react';

function App() {

  const [servingSize, setServingSize] = useState('1 unit')
  const [supplement, setSupplement] = useState('vitamin x units')
  const [dailyValue, setDailyValue] = useState('0%')

  return (
    <div>
    <Label servingSize={servingSize} supplement={supplement} dailyValue={dailyValue} />
    <Container>
    <h3>Settings</h3>
    <Row>
    <Col>
    <Form>
      <Form.Group controlId='servingsize'>
        <Form.Label>Serving Size</Form.Label>
        <Form.Control value={servingSize} onChange={e => setServingSize(e.target.value)}></Form.Control>
      </Form.Group>
      <Form.Group controlId='supplement'>
        <Form.Label>Supplement Name</Form.Label>
        <Form.Control value={supplement} onChange={e => setSupplement(e.target.value)}></Form.Control>
      </Form.Group>
      <Form.Group controlId='dailyvalue'>
        <Form.Label>Daily Value</Form.Label>
        <Form.Control value={dailyValue} onChange={e => setDailyValue(e.target.value)}></Form.Control>
      </Form.Group>

    </Form>
    </Col>
    <Col></Col>
    </Row>
    </Container>
    </div>
  );
}

export default App;
